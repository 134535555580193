
import { invoicePaymentMethods, invoiceStatuses } from '@/shared/config/invoices';
import { datesCompare, parsePrice } from '@/shared/helpers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
// const invoicesStatuses = [
//     {id: 'new', name: 'Nowy', color: 'default'},
//     {id: 'in-progress', name: 'W trakcie', color: 'primary'},
//     {id: 'conditionally-approved', name: 'Warunkowo', color: 'warning'},
//     {id: 'approved', name: 'Zatwierdzony', color: 'success'},
// ];
@Component({})
export default class InvoiceRow extends Vue {
    @Prop(Object) public item!: any;
    @Prop(Array) public itemsHeaders!: any;
    public parsePrice = parsePrice;
    public paymentMethod(name: string) {
        return invoicePaymentMethods.find((obj) => obj.id === name);
    }
        public statusType(statusType: string) {
        return invoiceStatuses.find((obj) => obj.id === statusType);
    }
      public invoiceColor(invoice: any) {
        if (invoice.paid) {
            return 'success';
        }

        return datesCompare(String(invoice.payment_deadline_date)) ? 'default' : 'warning';
    }

    //     public statusType(statusType: string) {
    //         return invoicesStatuses.find((obj) => obj.id === statusType);
    //     }
}
